<template>
  <div class="page" ref="bodyPage">
    <Disclaimer />
    <TopNav></TopNav>
    <Tops id="headnav" style ="position:fixed; width:100%; z-index:500"></Tops>
    <div  class="">
      <div  class="">
          <div v-if="$store.getters.store.has_prepared_food == 1 ">
                  <div v-if = "$store.getters.store.has_prepared_food == 1 && $store.getters.store.launched_preparedfood == 1 && isStoreclosed == false "  >
                    <div   class=" content-desktop  foodcat d-none  d-md-block " style="height: 47px; background-color: white;position:fixed;margin-top: 0px;width: 100%; z-index: 500;" >
                      <ul class="container "style=" width: 100%;font-size: 12px;margin-top: -10px;">
                          <li><a href="#maincourse" :class="{ active: isMainCourseSection }" class="foodnav">Main Course <div v-show="isMainCourseSection" style="height: 3px;" ></div></a></li>
                          <li><a href="#pastries" :class="{ active: isPastriesSection }" class="foodnav">Pastries <div v-show="isPastriesSection" style="height: 3px;" ></div></a></li>
                          <li><a href="#Proteins" :class="{ active: isProteinSection }" class="foodnav">Proteins <div v-show="isProteinSection" style="height: 3px;" ></div></a></li>
                          <li><a href="#SideMeal" :class="{ active: isSideMealSection }"  class="foodnav">Side Meal <div v-show="isSideMealSection" style="height: 3px;" ></div></a></li>
                          <li><a href="#Sauce" :class="{ active: isSauceSection }" class="foodnav">Sauce <div v-show="isSauceSection" style="height: 3px;" ></div></a> </li>
                          <li><a href="#Cakes" :class="{ active: isCakesSection }" class="foodnav">Cakes and Ice Creams <div v-show="isCakesSection" style="height: 3px;" ></div></a> </li>
                          <li><a href="#Bread" :class="{ active: isBreadSection }" class="foodnav">Bread <div v-show="isBreadSection" style="height: 3px;" ></div></a> </li>
                          <li><a href="#Swallow" :class="{ active: isSwallowSection }" class="foodnav">Swallow <div v-show="isSwallowSection" style="height: 3px;" ></div></a> </li>
                          <li><a href="#Soup" :class="{ active: isSoupSection }" class="foodnav">Soup <div v-show="isSoupSection" style="height: 3px;" ></div></a>  </li>
                          <li> <a href="#drinks" :class="{ active: isDrinksSection }" class="foodnav">Drinks <div v-show="isDrinksSection" style="height: 3px;" ></div></a> </li>
                      </ul>
                    </div>
                    <br>
                    <div id="content-mobile"  >
                        <div class="foodcat foodcatnav foodm" style="position:sticky; top:0px; background-color: white;background-color: white; width: 100%;height: 40px;">
                            <ul  style=" margin-left:-43px;" >
                              <li><a href="#maincourse2" :class="{ active: isMainCourseSection }" class="foodnav">Main Course <div v-show="isMainCourseSection" style="height: 3px;" ></div></a></li>
                              <li><a href="#pastries2" :class="{ active: isPastriesSection }" class="foodnav">Pastries <div v-show="isPastriesSection" style="height: 3px;" ></div></a></li>
                              <li><a href="#Proteins2" :class="{ active: isProteinSection }" class="foodnav">Proteins <div v-show="isProteinSection" style="height: 3px;" ></div></a></li>
                              
                              <li class="menu-link dropdown">
                                      <a href="" class="dropdown-toggle" data-toggle="dropdown" >MORE</a>
                                      <ul class="dropdown-menu">
                                          <li><a href="#SideMeal2"  class="foodnav">Side Meal <div v-show="isSideMealSection" style="height: 3px;" ></div></a></li>           
                                          <li class="divider"><a href="#Sauce2"  class="foodnav">Sauce <div v-show="isSauceSection" style="height: 3px;" ></div></a> </li>
                                          <li class="divider"><a href="#Cakes2" class="foodnav">Cakes and Ice Creams <div v-show="isCakesSection" style="height: 3px;" ></div></a> </li>
                                          <li class="divider"><a href="#Bread2" class="foodnav">Bread <div v-show="isBreadSection" style="height: 3px;" ></div></a> </li>
                                          <li class="divider"><a href="#Swallow2"  class="foodnav">Swallow <div v-show="isSwallowSection" style="height: 3px;" ></div></a> </li>
                                          <li class="divider"><a href="#Soup2"  class="foodnav">Soup <div v-show="isSoupSection" style="height: 3px;" ></div></a>  </li>
                                          <li class="divider">  <a href="#drinks2" class="foodnav">Drinks <div v-show="isDrinksSection" style="height: 3px;" ></div></a> </li>
                                      </ul>
                              </li>      
                          </ul>
                        </div>
                        <br>
                    </div>
          
                  <div class="content-desktop container" style="margin-top: 0px;">
                      <!-- food item product list -->
                      <div id="maincourse">
                        <div class="section product-group container bg-white my-5 " >
                          <div class="row header" >
                            <h5 class="col-6 title text-left mt-3"><a href="promotions/Specials" style="color: white; " >Main Course</a></h5>
                            <h5 class="col-6 title view-more text-right mt-3"><a href="/category/Meals" style="color: #ff0000;">  </a></h5>
                          </div>

                            <div class="row">
                              <div v-if="product.food_category === 'Main Course'"
                                v-for="(product, index) in products.food"
                                v-bind:key="index" 
                                class="col-6 col-md-3 col-lg-2 p-1"
                                >
                                
                                <!--<Product :product="product" 
                                v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="product.id" />-->

                                <FoodProduct  :product="product" 
                                v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="product.id" />
                              </div>
                          </div>

                          
                        </div>
                      </div>    
                        <!--pastries-->
                      <div id="pastries">
                        <div class="section product-group container bg-white my-5 " >
                          <div class="row header" >
                          <h5 class="col-6 title text-left mt-3"><a href="promotions/Specials" style="color: white; " >Pastries</a></h5>
                          <h5 class="col-6 title view-more text-right mt-3"><a href="/category/Meals" style="color: #ff0000;"> </a></h5>
                          </div>

                          <div class="row">
                            <div v-if="product.food_category === 'Pastries'"
                              v-for="(product, index) in products.food"
                              v-bind:key="index"
                              class="col-6 col-md-3 col-lg-2 p-1"
                            >
                              <!--<Product :product="product" 
                              v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="product.id" />-->

                              <FoodProduct :product="product" 
                              v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="product.id" />
                            </div>
                          </div>

                        </div>
                      </div>
                        <!--protein-->  
                        <div id="Proteins">              
                          <div class="section product-group container bg-white my-5 "  ref="Proteins">
                            <div class="row header" >
                              <h5 class="col-6 title text-left mt-3"><a href="promotions/Specials" style="color: white; " >Proteins</a></h5>
                              <h5 class="col-6 title view-more text-right mt-3"><a href="/category/Meals" style="color: #ff0000;"> </a></h5>
                            </div>

                            <div class="row">
                                <div v-if="product.food_category === 'Proteins'" 
                                  v-for="(product, index) in products.food"
                                  v-bind:key="index"
                                  class="col-6 col-md-3 col-lg-2 p-1" >
                                  <!--<Product :product="product" 
                                  v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="product.id" />-->

                                  <FoodProduct :product="product" 
                                  v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="product.id" />
                                </div>
                            </div>
                        </div>
                        </div>
                        <!--Side Meal-->
                        <!--@scroll="handleScroll"-->
                        <div  id="SideMeal">
                        <div class="section product-group container bg-white my-5 ">
                            <div class="row header" >
                              <h5 class="col-6 title text-left mt-3"><a href="promotions/Specials" style="color: white; " >Side Meal</a></h5>
                              <h5 class="col-6 title view-more text-right mt-3"><a href="/category/Meals" style="color: #ff0000;"> </a></h5>
                            </div>

                          <div class="row">
                            <div v-if="product.food_category === 'Side Meal'" 
                              v-for="(product, index) in products.food"
                              v-bind:key="index"
                              class="col-6 col-md-3 col-lg-2 p-1" >
                              <!--<Product :product="product" 
                              v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="product.id" />-->

                              <FoodProduct :product="product" 
                              v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="product.id" />
                            </div>
                        </div>
                        </div>
                        </div>

                        <!--Sauce-->
                        <div id="Sauce">
                        <div class="section product-group container bg-white my-5 " >
                            <div class="row header" >
                              <h5 class="col-6 title text-left mt-3"><a href="promotions/Specials" style="color: white; " >Sauce</a></h5>
                              <h5 class="col-6 title view-more text-right mt-3"><a href="/category/Meals" style="color: #ff0000;"> </a></h5>
                            </div>
                            <div class="row">
                                <div v-if="product.food_category === 'Sauce'" 
                                    v-for="(product, index) in products.food"
                                    v-bind:key="index"
                                    class="col-6 col-md-3 col-lg-2 p-1" >
                                    <!--<Product :product="product" 
                                    v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="product.id" />-->

                                    <FoodProduct :product="product" 
                                    v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="product.id" />
                                </div>
                            </div>
                        </div>
                        </div>
                        <!--Cakes and Ice Creams-->
                        <div id="Cakes">
                        <div class="section product-group container bg-white my-5 " >
                            <div class="row header" >
                              <h5 class="col-6 title text-left mt-3"><a href="promotions/Specials" style="color: white; " >Cakes and Ice Creams</a></h5>
                              <h5 class="col-6 title view-more text-right mt-3"><a href="/category/Meals" style="color: #ff0000;"> </a></h5>
                            </div>

                            <div class="row">
                              <div v-if="product.food_category === 'Cakes and Ice Creams'"
                                v-for="(product, index) in products.food"
                                v-bind:key="index"
                                class="col-6 col-md-3 col-lg-2 p-1"
                              >
                                <!--<Product :product="product" 
                                v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="product.id" />-->

                                <FoodProduct :product="product" 
                                v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="product.id" />
                              </div>
                            </div>
                        </div>
                        </div>
                        <!--Bread-->
                        <div id="Bread">
                        <div class="section product-group container bg-white my-5 " >
                              <div class="row header" >
                                <h5 class="col-6 title text-left mt-3"><a href="promotions/Specials" style="color: white; " >Bread</a></h5>
                                <h5 class="col-6 title view-more text-right mt-3"><a href="/category/Meals" style="color: #ff0000;"> </a></h5>
                              </div>

                                <div class="row">
                                  <div v-if="product.food_category === 'Bread'"
                                    v-for="(product, index) in products.food"
                                    v-bind:key="index"
                                    class="col-6 col-md-3 col-lg-2 p-1"
                                  >
                                    <!--<Product :product="product" 
                                    v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="product.id" />-->

                                    <FoodProduct :product="product" 
                                    v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="product.id" />
                                  </div>
                                </div>
                        </div>
                        </div>
                        <!--swallow-->
                        <div id="Swallow">
                        <div class="section product-group container bg-white my-5 " >
                          <div class="row header" >
                          <h5 class="col-6 title text-left mt-3"><a href="promotions/Specials" style="color: white; " >Swallow</a></h5>
                          <h5 class="col-6 title view-more text-right mt-3"><a href="/category/Meals" style="color: #ff0000;"> </a></h5>
                          </div>

                          <div class="row">
                            <div v-if="product.food_category === 'Swallow'" 
                              v-for="(product, index) in products.food"
                              v-bind:key="index"
                              class="col-6 col-md-3 col-lg-2 p-1" >
                              <!--<Product :product="product" 
                              v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="product.id" />-->

                              <FoodProduct :product="product" 
                              v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="product.id" />
                            </div>
                          </div>

                        
                        </div>
                        </div>
                        <!--Soup-->
                        <div  id="Soup">
                        <div class="section product-group container bg-white my-5 ">
                            <div class="row header" >
                              <h5 class="col-6 title text-left mt-3"><a href="promotions/Specials" style="color: white; " >Soup</a></h5>
                              <h5 class="col-6 title view-more text-right mt-3"><a href="/category/Meals" style="color: #ff0000;"> </a></h5>
                            </div>

                            <div class="row">
                              <div v-if="product.food_category === 'Soup'" 
                                v-for="(product, index) in products.food"
                                v-bind:key="index"
                                class="col-6 col-md-3 col-lg-2 p-1" >
                                <!--<Product :product="product" 
                                v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="product.id" />-->

                                <FoodProduct :product="product" 
                                v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="product.id" />
                              </div>
                            </div>

                        </div>
                        </div>   
                          <!--Drinks-->
                          <div id="drinks">
                          <div class=" section product-group container bg-white my-5 " >
                              <div class="row header">
                                <h5 class="col-6 title text-left mt-3"><a href="promotions/Specials" style="color: white; " >Drinks</a></h5>
                                <h5 class="col-6 title view-more text-right mt-3"><a href="/category/Meals" style="color: #ff0000;"> </a></h5>
                              </div>

                            <div class="row">
                              <div v-if="product.food_category === 'Drinks'" 
                                v-for="(product, index) in products.food"
                                v-bind:key="index"
                                class="col-6 col-md-3 col-lg-2 p-1" >
                                <!--<Product :product="product" 
                                v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="product.id" />-->

                                <FoodProduct :product="product" 
                                v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="product.id" />
                              </div>
                          </div>
                          </div>
                          </div>
                    </div>
                        <!-- Prepared Food Mobile View -->
                      <div id="content-mobile" class=" container" style="margin-top: 0px;">

                        <!-- food item product list -->
                        <div id="#maincourse2">
                          <div class="section product-group container bg-white my-5 " >
                            <div class="row header" >
                              <h5 class="col-6 title text-left mt-3"><a href="promotions/Specials" style="color: white; " >Main Course</a></h5>
                              <h5 class="col-6 title view-more text-right mt-3"><a href="/category/Meals" style="color: #ff0000;">  </a></h5>
                            </div>

                              <div class="row">
                                <div v-if="product.food_category === 'Main Course'"
                                  v-for="(product, index) in products.food"
                                  v-bind:key="index"
                                  class="col-6 col-md-3 col-lg-2 p-1"
                                  >
                                  <!--<Product :product="product" 
                                  v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="product.id" />-->

                                  <FoodProduct  :product="product" 
                                  v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="product.id" />
                                </div>
                            </div>

                            
                          </div>
                        </div>
                        <!--pastries-->
                        <div id="pastries2">
                           <div class="section product-group container bg-white my-5 " >
                          <div class="row header" >
                          <h5 class="col-6 title text-left mt-3"><a href="promotions/Specials" style="color: white; " >Pastries</a></h5>
                          <h5 class="col-6 title view-more text-right mt-3"><a href="/category/Meals" style="color: #ff0000;"> </a></h5>
                          </div>

                          <div class="row">
                            <div v-if="product.food_category === 'Pastries'"
                              v-for="(product, index) in products.food"
                              v-bind:key="index"
                              class="col-6 col-md-3 col-lg-2 p-1"
                            >
                             

                              <FoodProduct :product="product" 
                              v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="product.id" />
                            </div>
                          </div>

                        </div>
                         
                        </div>

                          <!--protein-->
                            <div id="Proteins2">              
                          <div class="section product-group container bg-white my-5 "  ref="Proteins2">
                            <div class="row header" >
                              <h5 class="col-6 title text-left mt-3"><a href="promotions/Specials" style="color: white; " >Proteins</a></h5>
                              <h5 class="col-6 title view-more text-right mt-3"><a href="/category/Meals" style="color: #ff0000;"> </a></h5>
                            </div>

                            <div class="row">
                                <div v-if="product.food_category === 'Proteins'" 
                                  v-for="(product, index) in products.food"
                                  v-bind:key="index"
                                  class="col-6 col-md-3 col-lg-2 p-1" >
                                  <!--<Product :product="product" 
                                  v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="product.id" />-->

                                  <FoodProduct :product="product" 
                                  v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="product.id" />
                                </div>
                            </div>
                        </div>
                         </div>
                          <!--Side Meal-->
                          <div class="section product-group container bg-white my-5 " id="SideMeal2">
                          <div class="row header" >
                            <h5 class="col-6 title text-left mt-3"><a href="promotions/Specials" style="color: white; " >Side Meal</a></h5>
                            <h5 class="col-6 title view-more text-right mt-3"><a href="/category/Meals" style="color: #ff0000;"> </a></h5>
                          </div>

                            <div class="row">
                              <div v-if="product.food_category === 'Side Meal'" 
                                v-for="(product, index) in products.food"
                                v-bind:key="index"
                                class="col-6 col-md-3 col-lg-2 p-1" >
                                <!--<Product :product="product" 
                                v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="product.id" />-->

                                <FoodProduct :product="product" 
                                v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="product.id" />
                              </div>
                          </div>

                          
                          </div>
                          
                          <!--Sauce-->
                          <div class="section product-group container bg-white my-5 " id="Sauce2">
                          <div class="row header" >
                            <h5 class="col-6 title text-left mt-3"><a href="promotions/Specials" style="color: white; " >Sauce</a></h5>
                            <h5 class="col-6 title view-more text-right mt-3"><a href="/category/Meals" style="color: #ff0000;"> </a></h5>
                          </div>

                            <div class="row">
                              <div v-if="product.food_category === 'Sauce'" 
                                v-for="(product, index) in products.food"
                                v-bind:key="index"
                                class="col-6 col-md-3 col-lg-2 p-1" >
                                <!--<Product :product="product" 
                                v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="product.id" />-->

                                <FoodProduct :product="product" 
                                v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="product.id" />
                              </div>
                          </div>

                          
                          </div>

                          <!--Cakes and Ice Creams-->
                          <div class="section product-group container bg-white my-5 " id="Cakes2">
                          <div class="row header" >
                            <h5 class="col-6 title text-left mt-3"><a href="promotions/Specials" style="color: white; " >Cakes and Ice Creams</a></h5>
                            <h5 class="col-6 title view-more text-right mt-3"><a href="/category/Meals" style="color: #ff0000;"> </a></h5>
                          </div>

                            <div class="row">
                              <div v-if="product.food_category === 'Cakes and Ice Creams'"
                                v-for="(product, index) in products.food"
                                v-bind:key="index"
                                class="col-6 col-md-3 col-lg-2 p-1"
                              >
                                <!--<Product :product="product" 
                                v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="product.id" />-->

                                <FoodProduct :product="product" 
                                v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="product.id" />
                              </div>
                            </div>

                          </div>
                        
                          <!--Bread-->
                          <div class="section product-group container bg-white my-5 " id="Bread2">
                          <div class="row header" >
                            <h5 class="col-6 title text-left mt-3"><a href="promotions/Specials" style="color: white; " >Bread</a></h5>
                            <h5 class="col-6 title view-more text-right mt-3"><a href="/category/Meals" style="color: #ff0000;"> </a></h5>
                          </div>

                            <div class="row">
                              <div v-if="product.food_category === 'Bread'"
                                v-for="(product, index) in products.food"
                                v-bind:key="index"
                                class="col-6 col-md-3 col-lg-2 p-1"
                              >
                                <!--<Product :product="product" 
                                v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="product.id" />-->

                                <FoodProduct :product="product" 
                                v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="product.id" />
                              </div>
                            </div>

                          </div>

                          <!--swallow-->
                          <div class="section product-group container bg-white my-5 " id="Swallow2">
                            <div class="row header" >
                            <h5 class="col-6 title text-left mt-3"><a href="promotions/Specials" style="color: white; " >Swallow</a></h5>
                            <h5 class="col-6 title view-more text-right mt-3"><a href="/category/Meals" style="color: #ff0000;"> </a></h5>
                            </div>

                            <div class="row">
                              <div v-if="product.food_category === 'Swallow'" 
                                v-for="(product, index) in products.food"
                                v-bind:key="index"
                                class="col-6 col-md-3 col-lg-2 p-1" >
                                <!--<Product :product="product" 
                                v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="product.id" />-->

                                <FoodProduct :product="product" 
                                v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="product.id" />
                              </div>
                            </div>

                          
                          </div>

                          <!--Soup-->
                          <div class="section product-group container bg-white my-5 " id="Soup2">
                              <div class="row header" >
                                <h5 class="col-6 title text-left mt-3"><a href="promotions/Specials" style="color: white; " >Soup</a></h5>
                                <h5 class="col-6 title view-more text-right mt-3"><a href="/category/Meals" style="color: #ff0000;"> </a></h5>
                              </div>

                              <div class="row">
                                <div v-if="product.food_category === 'Soup'" 
                                  v-for="(product, index) in products.food"
                                  v-bind:key="index"
                                  class="col-6 col-md-3 col-lg-2 p-1" >
                                  <!--<Product :product="product" 
                                  v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="product.id" />-->

                                  <FoodProduct :product="product" 
                                  v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="product.id" />
                                </div>
                              </div>

                          </div>
                        
                          <!--Drinks-->
                          <div class=" section product-group container bg-white my-5 " id="Drinks2">
                          <div class="row header" >
                            <h5 class="col-6 title text-left mt-3"><a href="promotions/Specials" style="color: white; " >Drinks</a></h5>
                            <h5 class="col-6 title view-more text-right mt-3"><a href="/category/Meals" style="color: #ff0000;"> </a></h5>
                          </div>

                            <div class="row">
                              <div v-if="product.food_category === 'Drinks'" 
                                v-for="(product, index) in products.food"
                                v-bind:key="index"
                                class="col-6 col-md-3 col-lg-2 p-1" >
                                <!--<Product :product="product" 
                                v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="product.id" />-->

                                <FoodProduct :product="product" 
                                v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="product.id" />
                              </div>
                          </div>
                          </div>
                            <!-- complimentary items list 
                            <div class="product-group container bg-white my-5"  id="drin">
                              <div class="row header" >
                                <h5 class="col-6 title text-left mt-3"><a href="/category/water" style="color: white; " >Water</a></h5>
                                <h5 class="col-6 title view-more text-right mt-3"><a href="/category/drinks" style="color: #ff0000;"></a></h5>
                              </div>

                              
                              <div class="row">
                                <div
                                  v-for="(product, index) in comp_products.water"
                                  v-bind:key="index"
                                  class="col-xs-6 col-sm-3 col-lg-2 p-1"
                                >
                                  <Product :product="product" 
                                  v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="product.id" />

                                  <FoodProduct :product="product" 
                                  v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="product.id" />
                                </div>  
                              </div>       

                              
                            </div>--> 
                      </div> <!-- End ofPrepared Food Mobile View -->
                  <div>
          </div>
                   </div> 
                  <div v-else-if = "$store.getters.store.has_prepared_food == 1 && $store.getters.store.launched_preparedfood == 1 && isStoreclosed == true " class="nonefood">
                   <div class="space"></div>
                    <p class="foodnotfound"><br>
                    <img src="../assets/img/Mobile/closed-sign2.png" style="margin-top:15px" /> <br><br>
                    Ordering for Prepared Food products is currently closed.<br>
                    Not to worry, it will re-open at &nbsp;{{food_window.openingtime}}<br>
                    
                    </p>
                    <div class="btn ml-2 mb-2" @click="$router.push('/home#menu')"> Shop For Groceries</div></p>
                  </div>
                  <div v-else-if="$store.getters.store.has_prepared_food == 1 || $store.getters.store.launched_preparedfood == 0 && isStoreclosed == false "  class="nonefood" >
                   <div class="space"></div>
                    <p class="foodnotfound"><br>
                    <img src="../assets/img/Mobile/no-food.png" /> <br>
                    Prepared Food products will soon be available online.<br> Please check back shortly<br>
                    
                    </p>
                    <div class="btn ml-2 mb-2" @click="$router.push('/home#menu')">Shop for Groceries</div></p>
                  </div>
          </div>  <!-- End of Prepared Food View -->
          <div v-else class="nonefood" >
                   <div class="space"></div>
                    <p class="foodnotfound">
                    <img src="../assets/img/Mobile/no-food.png" /> <br>
                   We are yet to produce prepared food at  <b>{{ $store.getters.store.name}} Store</b> .<br>
                   Please  <a v-if="$route.name !==('Checkout') && $route.name !==('StoreLocator')" href=""class="badge badge-pill badge-light ml-1" data-toggle="modal"data-target="#store"style=" border-radius: 3px; font-size:14px">Change Location</a>
                          </a> and choose a store that has the food <img src="../assets/img/prepared.png" alt="" class="mr-1" style="height:18px"
                  >icon..<br>
                    
                    </p>
                    <div class="btn ml-2 mb-2" @click="$router.push('/home#menu')">Shop for Groceries</div></p>
          </div>
      </div>

      <storeSelector></storeSelector>
      <Questionnaire></Questionnaire>
   
   
      <!-- Product Modal -->
      <PreparedFoodModal :viewproduct="viewproduct" :pro="pro" :products="products"/>
    </div>
    <!-- <FoodCart :home_products="products"></FoodCart>  -->
    <Cart :home_products="products"></Cart>

    <!--<pageDescription />-->
    <Footer></Footer>
  </div>
</template>
<style scoped>
.badge-light {
    color: #ffffff;
    background-color: #000066;
}
.router:hover {
  cursor: pointer;
  width: 100% !important;
}
.header {
  background-color: #000066;
  /* border-radius: 25% 25% 0 0; */
  border-radius: 8px 8px 0 0;
}

@media screen and (max-width: 500px) {
 
  .slider-item img {
    width: 100%;
    height: 100%;
  }
}
.btn {
  background: #000066 !important;
  color: #ffffff !important;
  box-shadow: 2px 3px #ccc;
  /* margin-bottom: 10px; */
}
.content-desktop {
  display: block;
}
#content-mobile {
  display: none;
}

@media screen and (max-width: 500px) {
  .foodcat ul li {
    display: inline-block;
    padding: 10px !important;
    list-style: none;
    font-family: harabara;
    font-size: 12px;
  }
  .foodcat ul li a {
    color: black;
    font-weight: bold;
  }

  .content-desktop {
    display: none;
  }
  #content-mobile {
    display: block;
  }
}
.space{
  height:50px;
}
.nonefood {
  height: 390px;
  font-size: 15px;
  background: white;
  text-align: center;
}

.foodnotfound {
  padding: 10px !important;
}
.six {
  height: 500px !important;
}

.foodcat ul li {
  display: inline-block;
  padding: 23px  !important;;
  list-style: none;
  font-family: harabara;
}

.foodcat ul li a.active div {
  background-color: #000066;
  transition: all linear 0.5s;
}
.foodcat ul li a {
  color: #000066 !important;
  font-size: 14px;
  font-weight: 700;

}
.dropdown-menu{
   border-radius: 2px;
    border-top:none !important;
    border-bottom: 4px solid #006;
    padding: 10px;
    width: 219px;
    font-size: 14px;
    max-height: 58vh;
    overflow: hidden;
    overflow-y: scroll;
    transform: none !important;
    top: 35px !important;
    left:-87px !important;
}
#maincourse{

  scroll-margin-top: 200px;

}
#pastries{

  scroll-margin-top: 200px;

}
#Proteins{

  scroll-margin-top: 200px;

}
#SideMeal{

  scroll-margin-top: 200px;

}

#Sauce{

  scroll-margin-top: 200px;
}
#Cakes{

  scroll-margin-top: 200px;
}
#Bread{

  scroll-margin-top: 200px;
}

#Swallow{
   scroll-margin-top: 200px;
}

#Soup{
   scroll-margin-top: 200px;
}
#drinks{
   scroll-margin-top: 200px;
}


#maincourse2{

  scroll-margin-top: 200px;

}
#pastries2{

  scroll-margin-top: 200px;

}
#Proteins2{

  scroll-margin-top: 200px;

}
#SideMeal2{

  scroll-margin-top: 200px;

}

#Sauce2{

  scroll-margin-top: 200px;
}
#Cakes2{

  scroll-margin-top: 200px;
}
#Bread2{

  scroll-margin-top: 200px;
}

#Swallow2{
   scroll-margin-top: 200px;
}

#Soup2{
   scroll-margin-top: 200px;
}
#drinks2{
   scroll-margin-top: 200px;
}
/*.home, #app{
background: black !important;
}*/
</style>

<script>
import * as $ from "jquery";
import StoreSelector from "@/components/StoreSelector.vue";
import Cart from "@/components/CartComponent.vue";
// import FoodCart from '@/components/FoodCart.vue'
import TopNav from "@/components/TopNav.vue";
import Tops from "@/components/Tops.vue";
import Footer from "@/components/Footer.vue";
import Disclaimer from "@/components/Disclaimer.vue";
import Questionnaire from "@/components/Questionnaire";
import Product from "@/components/Product.vue";
import FoodProduct from "@/components/FoodProduct.vue";
import PreparedFoodModal from "@/components/PreparedFoodModal.vue";
import pageDescription from "@/components/pageDescription.vue";
import moment from "moment";
import vuescroll from "vue-scroll";
import Scrollspy from 'vue2-scrollspy';

import Intersect from "vue-intersect";

export default {
  name: "PreparedFood",
  components: {
    FoodProduct,
    StoreSelector,
    Cart,
    TopNav,
    Tops,
    Footer,
    Disclaimer,
    Questionnaire,
    Product,
    PreparedFoodModal,
    pageDescription,
    'v-scroll': vuescroll ,
    Intersect,
Scrollspy,
  },

  data() {
    return {
      isStoreclosed: false,
      isLessProducts: true,
      image_url: this.$request.url,
      showSearch: false,
      products: {},
      prod:[],
      comp_products: {},
      pro: "",
      viewproduct: false,
      loader: "",
      categories: [],
      promotions: [],
      banners: [],
      store_open:"",
      // section booleans
      isMainCourseSection: false,
      isPastriesSection: false,
      isProteinSection: false,
      isSideMealSection: false,
      isSauceSection: false,
      isCakesSection: false,
      isBreadSection: false,
      isSwallowSection: false,
      isSoupSection: false,
      isDrinksSection: false,
    };
  },

  beforeMount() {
    this.$store.dispatch("ToggleShowSearch", true);
    this.loader = this.$loading.show();
  },
  created() {
    this.banners = this.$store.getters.banners.filter(
      (image) => image.category == null || image.category == "null"
    );
    // this.banners = this.banners != [] ? this.banners.reverse() : this.banners;
  },

  mounted() {
    if (!localStorage.getItem("black_friday_modal")) {
      localStorage.setItem("black_friday_modal", true);
      $("#myModal").modal("show");
    }
    
    this.fetchProducts();
    this.fetchComplimentaryProducts();
    //this.foodpnav()
    this.fetchBanners();
    this.fetchCategories();
    this.fetchPromotions();
    this.storeOpen();
    window.addEventListener("scroll", this.updateScroll);
  },
  computed: {},

  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title =
          "Market Square | Online Shopping | Groceries & Household Appliances | Find More, Pay Less";
      },
    },
  },
  methods: {
    updateScroll() {
      // This is a temporary solution based on position of scroll
      let result = window.scrollY - this.getOffset(this.$refs.bodyPage);
      console.log(result)
      if (result >= 0 && result < 720) {
        this.offAllSectionView();
        this.isMainCourseSection = true;
      }

      if (result > 720 && result < 1160) {
        this.offAllSectionView();
        this.isPastriesSection = true;
      }

      if (result > 1160 && result < 1560) {
        this.offAllSectionView();
        this.isProteinSection = true;
      }

      if (result > 1560 && result < 2000) {
        this.offAllSectionView();
        this.isSideMealSection = true;
      }

      if (result > 2000 && result < 2400) {
        this.offAllSectionView();
        this.isSauceSection = true;
      }

      if (result > 2400 && result < 3200) {
        this.offAllSectionView();
        this.isCakesSection = true;
      }

      if (result >3200  && result < 3800) {
        this.offAllSectionView();
        this.isBreadSection = true;
      }

      if (result >3800 && result < 3900) {
        this.offAllSectionView();
        this.isSwallowSection = true;
      }

      if (result > 3896 && result < 4360) {
        this.offAllSectionView();
        this.isSoupSection = true;
      }

      if (result > 4360) {
        this.offAllSectionView();
        this.isDrinksSection = true;
      }

     
    },
    getOffset(el) {
      let bodyRect = this.$refs.bodyPage.getBoundingClientRect();
      let elemRect = el.getBoundingClientRect();
      let offset = elemRect.top - bodyRect.top;
      return offset;

      // alert('Element is ' + offset + ' vertical pixels from <body>');

      // const rect = el.getBoundingClientRect();
      // return {
      //   left: rect.left + window.scrollX,
      //   top: rect.top + window.scrollY,
      // };
    },
    
    offAllSectionView() {
// This will ensure any section in view will be disabled
      this.isMainCourseSection = false;
      this.isPastriesSection = false;
      this.isProteinSection = false;
      this.isSideMealSection = false;
      this.isSauceSection = false;
      this.isCakesSection = false;
      this.isBreadSection = false;
      this.isSwallowSection = false;
      this.isSoupSection = false;
      this.isDrinksSection = false;
    },
   
    storeOpen() {
      this.food_window = [];
      let req = {
        what: "foodwindows",
        showLoader: false,
        params: {
          foodwindow: this.$store.getters.store.mode,
          storeid: this.$store.getters.store.id,
        },
      };
      this.$request.makeGetRequest(req).then((response) => {
        if (response.type == "foodwindows") {
          this.food_window = response.data.data[0].food_open_window[0];
          let dt = new Date();

          let store_open = moment(this.food_window.openingtime, [
            "h:mm A",
          ]).format("HH:mm");
          let store_close = moment(this.food_window.closingtime, [
            "h:mm A",
          ]).format("HH:mm");

          console.log(store_open, "i see opening");
          console.log(store_close, "i see opening");

          let s = store_open.split(":");
          let dt1 = new Date(
            dt.getFullYear(),
            dt.getMonth(),
            dt.getDate(),
            parseInt(s[0]),
            parseInt(s[1])
          );

          let e = store_close.split(":");
          let dt2 = new Date(
            dt.getFullYear(),
            dt.getMonth(),
            dt.getDate(),
            parseInt(e[0]),
            parseInt(e[1])
          );
          if (dt >= dt1 && dt <= dt2) {
            this.isStoreclosed = false;
          } else {
            this.isStoreclosed = true;
          }
        }
      });
    },

   
    navAnimation() {
      let header = document.querySelector(".section");
      let nav = document.querySelector("ul");
      const options = {};

      let observer = new IntersectionObserver((entries) => {
        entries.forEach((e) => {
          console.log(e);
        });
      }, options);
      header.forEach((section) => {
        observer.observe(section);
      });
    },
       foodpnav(){
        document.querySelectorAll('a[href^="#"]').forEach(anchoe =>{
          anchor.addEventListener("click", function(e){
            e.preventDefault();
            document.querySelector(this.getAttribute("href")).scrollIntoView({
              behavior : "smooth"
            });
          });
        })
       }
       ,
    goToCategory(name) {
      switch (name) {
        case "Black Friday":
          return "/black-friday";
          break;
        case "First Image":
          return "/category/sda";
          break;
        case "Second Image":
          return "/category/medicare";
          break;
        case "Third Image":
          return "/category/spirits";
          break;
        case "landing page":
          return "/category/fresh produce";
          break;
        case "banner_5":
          return "/category/groceries";
          break;
        default:
          return null;
      }

      let res = Number(
        localStorage.getItem("black-friday-modal") != undefined
          ? localStorage.getItem("black-friday-modal")
          : 0
      );

      localStorage.setItem("black-friday-modal", res + 1);
    },
    goTo(name) {
      let result = this.goToCategory(name);

      if (result != null) {
        this.$router.push(result);
      } else if (name.toLowerCase().includes("xmas")) {
        this.$router.push("/category/hampers");
      }
    },
    fetchBanners() {
      let req = {
        what: "banners",
        showLoader: false,
      };
      this.$request
        .makeGetRequest(req)
        .then((response) => {
          if (response.type == "banners") {
            this.banners = response.data.data.filter(
              (image) => image.category == null || image.category == "null"
            );
            this.$store.dispatch("banners", response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchCategories() {
      let req = {
        what: "getCategories",
        showLoader: false,
        params: {
          store_id: this.$store.getters.store.id,
        },
      };
      this.$request
        .makeGetRequest(req)
        .then((response) => {
          if (response.type == "getCategories") {
            this.categories = response.data.data;
            this.$store.dispatch("categories", response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(this.categories);
        });
    },
    fetchPromotions() {
      let req = {
        what: "getPromotions",
        showLoader: false,
        params: {
          store_id: this.$store.getters.store.id,
        },
      };
      this.$request
        .makeGetRequest(req)
        .then((response) => {
          if (response.type == "getPromotions") {
            this.categories = response.data.data;
            this.$store.dispatch(
              "promotions",
              response.data.data.filter((val) => val != null)
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchProducts() {
      let req = {
        what: "foodproductsinstore",
        showLoader: false,
        params: {
          storeid: this.$store.getters.store.id,
        },
      };
      this.$request
        .makeGetRequest(req)
        .then((res) => {
          if (res.type == "foodproductsinstore") {
            console.log(res.data.data);
            let cart = this.$store.getters.foodcart;
            this.loader.hide();

            if (res.data.data.food.length > 0) {
              res.data.data.food.forEach((i) => {
                i.hidebtn = false;
                i.hideqty = true;
                i.cart_qty =
                  i.description.includes("/KG") ||
                  i.description.includes("/ KG")
                    ? 1.0
                    : 1;
                cart.forEach((j) => {
                  if (i.id == j.product.id) {
                    i.hidebtn = true;
                    i.hideqty = false;
                    i.cart_qty = j.quantity;
                  }
                });
              });
              // res.data.data.beverages.forEach(i => {
              //   i.hidebtn = false;
              //   i.hideqty = true;
              //   i.cart_qty = i.description.includes('/KG') || i.description.includes('/ KG') ? 1.0 : 1;
              //   cart.forEach(j => {
              //     if (i.id == j.product.id) {
              //       i.hidebtn = true;
              //       i.hideqty = false;
              //       i.cart_qty = j.quantity;
              //     }

              //   })
              // })
              // res.data.data.water.forEach(i => {
              //   i.hidebtn = false;
              //   i.hideqty = true;
              //   i.cart_qty = i.description.includes('/KG') || i.description.includes('/ KG') ? 1.0 : 1;
              //   cart.forEach(j => {
              //     if (i.id == j.product.id) {
              //       i.hidebtn = true;
              //       i.hideqty = false;
              //       i.cart_qty = j.quantity;
              //     }

              //   })
              // })
              // res.data.data.commodities.forEach(i => {
              //   i.hidebtn = false;
              //   i.hideqty = true;
              //   i.cart_qty = i.description.includes('/KG') || i.description.includes('/ KG') ? 1.0 : 1;
              //   cart.forEach(j => {
              //     if (i.id == j.product.id) {
              //       i.hidebtn = true;
              //       i.hideqty = false;
              //       i.cart_qty = j.quantity;
              //     }

              //   })
              // })
              this.products = res.data.data;

              console.log(this.products);
            } else {
              this.$swal(
                "Empty store",
                `This store currently has no products. Please choose another store`,
                "error"
              );
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    fetchComplimentaryProducts() {
      let req = {
        what: "products",
        showLoader: false,
        params: {
          storeid: this.$store.getters.store.id,
        },
      };
      this.$request
        .makeGetRequest(req)
        .then((res) => {
          if (res.type == "products") {
            console.log(res.data.data);
            let cart = this.$store.getters.cart;
            this.loader.hide();
            if (res.data.data.top.length > 0) {
              res.data.data.top.forEach((i) => {
                i.hidebtn = false;
                i.hideqty = true;
                i.cart_qty =
                  i.description.includes("/KG") ||
                  i.description.includes("/ KG")
                    ? 1.0
                    : 1;
                cart.forEach((j) => {
                  if (i.id == j.product.id) {
                    i.hidebtn = true;
                    i.hideqty = false;
                    i.cart_qty = j.quantity;
                  }
                });
              });
              res.data.data.beverages.forEach((i) => {
                i.hidebtn = false;
                i.hideqty = true;
                i.cart_qty =
                  i.description.includes("/KG") ||
                  i.description.includes("/ KG")
                    ? 1.0
                    : 1;
                cart.forEach((j) => {
                  if (i.id == j.product.id) {
                    i.hidebtn = true;
                    i.hideqty = false;
                    i.cart_qty = j.quantity;
                  }
                });
              });
              res.data.data.water.forEach((i) => {
                i.hidebtn = false;
                i.hideqty = true;
                i.cart_qty =
                  i.description.includes("/KG") ||
                  i.description.includes("/ KG")
                    ? 1.0
                    : 1;
                cart.forEach((j) => {
                  if (i.id == j.product.id) {
                    i.hidebtn = true;
                    i.hideqty = false;
                    i.cart_qty = j.quantity;
                  }
                });
              });
              res.data.data.commodities.forEach((i) => {
                i.hidebtn = false;
                i.hideqty = true;
                i.cart_qty =
                  i.description.includes("/KG") ||
                  i.description.includes("/ KG")
                    ? 1.0
                    : 1;
                cart.forEach((j) => {
                  if (i.id == j.product.id) {
                    i.hidebtn = true;
                    i.hideqty = false;
                    i.cart_qty = j.quantity;
                  }
                });
              });

              this.comp_products = res.data.data;
            } else {
              this.$swal(
                "Empty store",
                `This store currently has no products. Please choose another store`,
                "error"
              );
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    viewProduct(product) {
      this.pro = product;
      this.viewproduct = true;
    },
    // foodNavigBar() {
    //   let container = document.querySelectorAll(".product-group");
    //   let navlinks = document.querySelectorAll(".foodnav");

    //   window.onScroll = () => {
    //     container.forEach((con) => {
    //       let top = window.scrollY;
    //       let offset = con.offsetTop;
    //       let height = con.offsetHeight;
    //       let id = con.getAttribute("id");

    //       //   if(top >= offset && top < offset + height){
    //       //     navlinks.forEach(links =>{
    //       //       links.classList.remove('active');
    //       //       document.querySelector('.foodnav[href*='+id+' ]').classList.add('active')
    //       //     })
    //       // }

    //       if (top >= offset && top < offset + height) {
    //         navlinks.forEach((item) => {
    //           item.classList.remove("active");
    //           if (item.querySelector("a").getAttribute("href") == "#" + id) {
    //             item.classList.add("active");
    //           }
    //         });
    //       }
    //     });
    //   };
    // },
  },
  destroy() {
    // Removing event listener when component is being destroyed, in order to prevent leaks:
    window.removeEventListener("scroll", this.updateScroll);
  },
  unmounted() {
    // Removing event listener when component is being destroyed, in order to prevent leaks:
    window.removeEventListener("scroll", this.updateScroll);
  },
};
</script>

